import { AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import * as rootReducer from './../../rootReducer';
import { AcademicsService as service } from './../../academics/service/service';
import { PublishComponent } from '../publish/component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

import { AppService } from './../../service/app.service';


import * as ICAL from 'ical.js';

// const ICAL: any = require('ical.js');

class model {
    showPublish: boolean = false;
    dates = [];
    monthOptions = [{ 'Name': 'Every / Any', 'Value': '*' }, { 'Name': 'Pick', 'Value': 'pick' }];
    allMonths = [{ 'Name': 'Jan', 'Value': '1' }, { 'Name': 'Feb', 'Value': '2' }, { 'Name': 'Mar', 'Value': '3' }, { 'Name': 'Apr', 'Value': '4' }, { 'Name': 'May', 'Value': '5' }, { 'Name': 'Jun', 'Value': '6' }, { 'Name': 'Jul', 'Value': '7' }, { 'Name': 'Aug', 'Value': '8' }, { 'Name': 'Sep', 'Value': '9' }, { 'Name': 'Oct', 'Value': '10' }, { 'Name': 'Nov', 'Value': '11' }, { 'Name': 'Dec', 'Value': '12' }];
    dateOptions = [{ 'Name': 'Every', 'Value': '*' }, { 'Name': 'Any', 'Value': '?' }, { 'Name': 'Last day of month', 'Value': 'L' }, { 'Name': 'Pick', 'Value': 'pick' }];
    allDates = [
        { 'Name': '1', 'Value': '1' }, { 'Name': '2', 'Value': '2' }, { 'Name': '3', 'Value': '3' }, { 'Name': '4', 'Value': '4' },
        { 'Name': '5', 'Value': '5' }, { 'Name': '6', 'Value': '6' }, { 'Name': '7', 'Value': '7' }, { 'Name': '8', 'Value': '8' },
        { 'Name': '9', 'Value': '9' }, { 'Name': '10', 'Value': '10' }, { 'Name': '11', 'Value': '11' }, { 'Name': '12', 'Value': '12' },
        { 'Name': '13', 'Value': '13' }, { 'Name': '14', 'Value': '14' }, { 'Name': '15', 'Value': '15' }, { 'Name': '16', 'Value': '16' },
        { 'Name': '17', 'Value': '17' }, { 'Name': '18', 'Value': '18' }, { 'Name': '19', 'Value': '19' }, { 'Name': '20', 'Value': '20' },
        { 'Name': '21', 'Value': '21' }, { 'Name': '22', 'Value': '22' }, { 'Name': '23', 'Value': '23' }, { 'Name': '24', 'Value': '24' },
        { 'Name': '25', 'Value': '25' }, { 'Name': '26', 'Value': '26' }, { 'Name': '27', 'Value': '27' }, { 'Name': '28', 'Value': '28' },
        { 'Name': '29', 'Value': '29' }, { 'Name': '30', 'Value': '30' }, { 'Name': '31', 'Value': '31' }];
    DOWOptions = [{ 'Name': 'Every', 'Value': '0' }, { 'Name': 'Any', 'Value': '?' }, { 'Name': 'First', 'Value': '1' }, { 'Name': 'Second', 'Value': '2' }, { 'Name': 'Third', 'Value': '3' }, { 'Name': 'Fourth', 'Value': '4' }, { 'Name': 'Last', 'Value': '5' }];
    DOWs = [
        { 'Name': 'Every Sunday', 'Value': '0', 'DOWName': 'Sun' }, { 'Name': 'Every Monday', 'Value': '1', 'DOWName': 'Mon' }, { 'Name': 'Every Tuesday', 'Value': '2', 'DOWName': 'Tue' },
        { 'Name': 'Every Wednesday', 'Value': '3', 'DOWName': 'Wed' }, { 'Name': 'Every Thursday', 'Value': '4', 'DOWName': 'Thu' }, { 'Name': 'Every Friday', 'Value': '5', 'DOWName': 'Fri' }, { 'Name': 'Every Saturday', 'Value': '6', 'DOWName': 'Sat' },
        { 'Name': 'First Sunday', 'Value': '0#1', 'DOWName': 'Sun' }, { 'Name': 'First Monday', 'Value': '1#1', 'DOWName': 'Mon' }, { 'Name': 'First Tuesday', 'Value': '2#1', 'DOWName': 'Tue' },
        { 'Name': 'First Wednesday', 'Value': '3#1', 'DOWName': 'Wed' }, { 'Name': 'First Thursday', 'Value': '4#1', 'DOWName': 'Thu' }, { 'Name': 'First Friday', 'Value': '5#1', 'DOWName': 'Fri' }, { 'Name': 'First Saturday', 'Value': '6#1', 'DOWName': 'Sat' },
        { 'Name': 'Second Sunday', 'Value': '0#2', 'DOWName': 'Sun' }, { 'Name': 'Second Monday', 'Value': '1#2', 'DOWName': 'Mon' }, { 'Name': 'Second Tuesday', 'Value': '2#2', 'DOWName': 'Tue' },
        { 'Name': 'Second Wednesday', 'Value': '3#2', 'DOWName': 'Wed' }, { 'Name': 'Second Thursday', 'Value': '4#2', 'DOWName': 'Thu' }, { 'Name': 'Second Friday', 'Value': '5#2', 'DOWName': 'Fri' }, { 'Name': 'Second Saturday', 'Value': '6#2', 'DOWName': 'Sat' },
        { 'Name': 'Third Sunday', 'Value': '0#3', 'DOWName': 'Sun' }, { 'Name': 'Third Monday', 'Value': '1#3', 'DOWName': 'Mon' }, { 'Name': 'Third Tuesday', 'Value': '2#3', 'DOWName': 'Tue' },
        { 'Name': 'Third Wednesday', 'Value': '3#3', 'DOWName': 'Wed' }, { 'Name': 'Third Thursday', 'Value': '4#3', 'DOWName': 'Thu' }, { 'Name': 'Third Friday', 'Value': '5#3', 'DOWName': 'Fri' }, { 'Name': 'Third Saturday', 'Value': '6#3', 'DOWName': 'Sat' },
        { 'Name': 'Fourth Sunday', 'Value': '0#4', 'DOWName': 'Sun' }, { 'Name': 'Fourth Monday', 'Value': '1#4', 'DOWName': 'Mon' }, { 'Name': 'Fourth Tuesday', 'Value': '2#4', 'DOWName': 'Tue' },
        { 'Name': 'Fourth Wednesday', 'Value': '3#4', 'DOWName': 'Wed' }, { 'Name': 'Fourth Thursday', 'Value': '4#4', 'DOWName': 'Thu' }, { 'Name': 'Fourth Friday', 'Value': '5#4', 'DOWName': 'Fri' }, { 'Name': 'Fourth Saturday', 'Value': '6#4', 'DOWName': 'Sat' },
        { 'Name': 'Last Sunday', 'Value': '0L', 'DOWName': 'Sun' }, { 'Name': 'Last Monday', 'Value': '1L', 'DOWName': 'Mon' }, { 'Name': 'Last Tuesday', 'Value': '2L', 'DOWName': 'Tue' },
        { 'Name': 'Last Wednesday', 'Value': '3L', 'DOWName': 'Wed' }, { 'Name': 'Last Thursday', 'Value': '4L', 'DOWName': 'Thu' }, { 'Name': 'Last Friday', 'Value': '5L', 'DOWName': 'Fri' }, { 'Name': 'Last Saturday', 'Value': '6L', 'DOWName': 'Sat' },
    ];
    instAcadFrom: Date;
    instAcadTo: Date;
    title: string;
    today: Date = new Date();
    showRemoveOccurance: boolean;
    selectedModel: any = {};
    selectedthis: any;
    instCalendarList: any[] = [];
    currentPersona: any;
    selectedDate: Date = new Date();

    showIcon: boolean = false;
}

@Component({
    selector: 'calendar-comp',
    templateUrl: './component.html',
    providers: [service]
})
export class LMSCalendarComponent implements OnInit, AfterViewInit {
    modalRef: BsModalRef;
    @Output() showIcon = new EventEmitter<any>();
    @Output() openComponentModal = new EventEmitter<any>();
    @Output() classSetup = new EventEmitter<any>();
    
    @ViewChild(PublishComponent) publishComponent!: PublishComponent;

    icalData: string = `
BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
UID:1234@example.com
DTSTAMP:20250101T090000Z
SUMMARY:Sample Event
DTSTART:20250101T100000Z
DTEND:20250101T110000Z
END:VEVENT
END:VCALENDAR
  `;

    ngAfterViewInit() {
        // Ensure the component is available before triggering the click
        if (this.publishComponent) {
            console.log('called')
            //this.triggerClick(); // Trigger click after view initialization
        }
    }
    public m: model;

    action: any;
    trigger: any;
    fullDate: string;
    date: string;
    inCurrentMonth: boolean;
    isToday: boolean;
    islessThanToday: boolean;
    isHoliday: boolean;
    isSelected: boolean;
    inAcadYear: boolean;
    eventCategory: string;
    events: any[] = [];
    calDate() {
        this.fullDate = "";
        this.date = "";
        this.inCurrentMonth = true;
        this.isToday = false;
        this.islessThanToday = false;
        this.isHoliday = false;
        this.isSelected = false;
        this.inAcadYear = true;
        this.events = [];
        this.eventCategory = '';
    };
    constructor(private store: Store<rootReducer.State>, private service: service, private componentFactoryResolver: ComponentFactoryResolver, private modalService: BsModalService, private appService: AppService) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
        this.init();

        this.parseICalData(this.icalData);
    }
    init_model() {
        this.m = new model();
    }
    init_store() {
    }
    sub_store() {
        this.store.select(rootReducer.get_state_app).subscribe((state: any) => {
            if (state) {
                this.m.currentPersona = state.currentPersona;
                this.m.instAcadFrom = new Date(this.m.currentPersona.acadYearFrom);
                this.m.instAcadTo = new Date(this.m.currentPersona.acadYearTo);
            }
        });
    }
    init() {
        this.service.getCalendarEvents().subscribe(res => {
            if (res) {
                this.m.instCalendarList = JSON.parse(res.body).data;
                console.log(this.m.instCalendarList)
                this.selectToday();
            }
        }, err => {
        });
    }

    parseICalData(data: string): void {
        try {
          const jcalData = ICAL.parse(data); // Parse the iCalendar string
          const comp = new ICAL.Component(jcalData);
          const events = comp.getAllSubcomponents('vevent'); // Extract all events
    
          events.forEach(event => {
            const eventObj = new ICAL.Event(event);
            console.log('Event Summary:', eventObj.summary);
            console.log('Start Date:', eventObj.startDate.toString());
            console.log('End Date:', eventObj.endDate.toString());
          });
        } catch (error) {
          console.error('Error parsing iCal data:', error);
        }
      }

    triggerClick() {

        var publish: TemplateRef<any>;
        // Trigger the click method of PublishComponent
        this.publishComponent.openModal(publish); // Assuming 'onClick' is the method to call

    }

    //action
    nextMonth() {
        var cd = this.m.selectedDate;
        var dt = new Date(cd.getFullYear(), cd.getMonth(), 1);
        dt.setMonth(dt.getMonth() + 1);
        this.m.selectedDate = dt;
        this.setDates(dt);
    };
    prevMonth() {
        var cd = this.m.selectedDate;
        var dt = new Date(cd.getFullYear(), cd.getMonth(), 1);
        dt.setMonth(dt.getMonth() - 1);
        this.m.selectedDate = dt;
        this.setDates(dt);
    };
    selectToday() {
        this.m.selectedDate = this.m.today;
        this.setDates(this.m.selectedDate);
    };
    selectDay(d) {
        this.m.showRemoveOccurance = true;
        this.m.selectedDate = d.fullDate;
        this.m.selectedModel = d;
        if (new Date(this.m.selectedDate) < new Date())
            this.m.selectedModel.islessThanToday = true;
        for (var i = 0; i < this.m.dates.length; i++) {
            this.m.dates[i].forEach(dt => { dt.isSelected = false; });
        }
        d.isSelected = true;


        this.m.showPublish = true;

        this.showIcon.emit();
        this.openComponentModal.emit();
        this.classSetup.emit();

        setTimeout(() => {
            this.m.showPublish = false;
          }, 5000); // Delay of 3 seconds
    };

    setDates(date) {
        if (date instanceof Date && !isNaN(date.valueOf())) {
            this.m.dates = [];
            var curMonth = this.m.selectedDate.getMonth();
            var datesWeekwise = this.getDatesWeekwise(date);
            for (var i = 0; i < datesWeekwise.length; i++) {
                var arr = [];
                datesWeekwise[i].forEach((dt: Date) => {
                    var cd = new this.calDate();
                    cd.date = dt.getDate();
                    dt.setHours(0, 0, 0, 0);
                    var td = this.m.today;
                    td.setHours(0, 0, 0, 0);
                    cd.fullDate = dt;
                    var iaf = Date.parse(this.m.currentPersona.acadYearFrom);
                    var iat = Date.parse(this.m.currentPersona.acadYearTo);
                    var fd = Date.parse(cd.fullDate);
                    cd.inCurrentMonth = cd.fullDate.getMonth() == curMonth;
                    cd.inAcadYear = fd > iaf && fd < iat;
                    cd.isToday = dt.getTime() == td.getTime();
                    cd.isHoliday = false;
                    cd.isExam = false;
                    cd.isEvent = false;
                    cd.eventCategory = '';
                    if (cd.isToday) { cd.isSelected = true; this.m.selectedModel = cd; }
                    _.each(this.m.instCalendarList, function (v, k) {
                        if (v.eventDates != '') {
                            _.each(v.eventDates, function (e, k) {
                                var evtDate = new Date(e.eventDate);
                                evtDate.setHours(0, 0, 0, 0);
                                if (evtDate.getTime() === dt.getTime()) {
                                    cd.events.push({ title: v.eventName, eventDate: e.eventDate });
                                    cd.eventCategory = v.eventCategoryAsString || '';

                                }
                            });
                        }
                    });
                    arr.push(cd);
                });
                this.m.dates.push(arr);
            }
        }
    };

    getDatesWeekwise(dt: Date) {
        var calendarDates: Date[] = [];
        var weeklyDates = [];
        if (this.isDate(dt)) {
            var date = new Date(dt);
            var firstDayOfMonth = this.getFirstDay(date);
            var dow = firstDayOfMonth.getDay();//0-6 sun-sat
            //add prev months dates.
            for (var i = 0; i < dow; i++) {
                var d = this.addDays(firstDayOfMonth, i - dow);
                calendarDates.push(d);
            }
            //add next 42 - dow -1 days
            for (var i = 0; i < 42 - dow; i++) {

                var d = this.addDays(firstDayOfMonth, i);
                calendarDates.push(d);
            }
        }
        return this.chunkArray(calendarDates, 7);
    };
    isDate(dt) {
        var date = new Date(dt);
        var b = date instanceof Date && !isNaN(date.valueOf());
        return b;
    };
    getFirstDay(date) {
        return new Date(date.getFullYear(), date.getMonth(), 1);
    };
    getLastDay(date) {
        if (this.isDate(date)) {
            return new Date(date.getFullYear(), date.getMonth() + 1, 0);
        }
    };
    addDays(date, n) {
        var time = date.getTime();
        return new Date(time + (n * 24 * 60 * 60 * 1000));
    };
    chunkArray = function (array, chunkSize) {
        var result = [];
        var i, j, temparray, chunk = chunkSize;
        for (i = 0, j = array.length; i < j; i += chunk) {
            temparray = array.slice(i, i + chunk);
            result.push(temparray);
        }
        return result;
    }

    removeOccurence(event) {
        var selModel = this.m.selectedModel;
        event.Date = this.m.selectedDate;
        selModel.events.splice(selModel.events.indexOf(event), 1);
        this.service.removeOccurence(event.title, event.eventDate).subscribe();
    };
}