import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';
import { TypeaheadMatch } from 'ngx-bootstrap';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { ConnectService } from '../../connect/service/service';
import { CTApi } from '../../../app/service/ct-api';
import { PulseService } from '../../service/pulse';
import { AppService } from '../../service/app.service';
import { CronData, KafkaMessage } from '../../service/app.interfaces';
import { Router } from '@angular/router';

const PageData = {
    "/fees/feesDefaultersListReport": {
        title: 'Fee Defaulters Report', worker: "fees", action: "fee_defaulters_report",
        mainTopic: 'fees', topic: 'fees-defaulters-report',
    },
    "/academics/calendar/events": {
        title: 'Calendar Events', worker: "calendar", action: "create_calendar_event"
    }
}

class Model {
    title: string = '';
    search: boolean = false;
    selectedMonths: any[] = [];
    searchedTopics: any[] = [];
    searchText: string = "";
    topics: any[] = [];
    mediums = [
        { name: "Calendar", checked: false },
        { name: "Email", checked: false },
        { name: "Notify", checked: false },
        { name: "SMS", checked: false },
        { name: "UI", checked: true, disabled: true }
    ];
    schedule: string = "now";
    cron = { minute: "0", hour: "0", day: "1", month: "0", dow: "0" };
    selfPublish: boolean = false;
    isTopicFieldDisabled: boolean = false;
    classSetup: boolean = false;

    duration: any;
}

@Component({
    selector: 'publish-to',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [ConnectService]
})
export class PublishToComponent implements OnInit, OnDestroy {
    public m: Model;
    topicList: any[] = [];
    dow = [
        { name: "Sun", checked: false },
        { name: "Mon", checked: false },
        { name: "Tue", checked: false },
        { name: "Wed", checked: false },
        { name: "Thu", checked: false },
        { name: "Fri", checked: false },
        { name: "Sat", checked: false }
    ];
    months = [
        { name: "Jan", checked: false },
        { name: "Feb", checked: false },
        { name: "Mar", checked: false },
        { name: "Apr", checked: false },
        { name: "May", checked: false },
        { name: "Jun", checked: false },
        { name: "Jul", checked: false },
        { name: "Aug", checked: false },
        { name: "Sep", checked: false },
        { name: "Oct", checked: false },
        { name: "Nov", checked: false },
        { name: "Dec", checked: false }
    ];
    hours: any[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    minutes: any[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];
    dates: any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    subscription = new Subscription();
    public payloadString: string = "";
    topicsStats: Array<any> = [];
    topicsStatsMaped: { [k: string]: any } = {};
    isViewAll: boolean = false;

    userId: any;

    @Input() classSetupPublish: any;

    constructor(private store: Store<rootReducer.State>, private connectService: ConnectService, public ctapi: CTApi,
        private pulseService: PulseService, public appService: AppService, private router: Router) { }

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    init_model() {
        this.m = new Model();
        this.topicList = [{ name: "/puc_i" }, { name: "/puc_i/pcmb" }];
        const pageData = PageData[this.router.url] || { title: '' };
        this.m.title = pageData.title;
        if (pageData.topic) {
            this.m.searchText = pageData.topic;
            this.m.isTopicFieldDisabled = true;
        }

        if (this.classSetupPublish == true) this.m.classSetup = true;
    }

    init_store() {
        this.payloadString = JSON.stringify(this.appService.publisherPayload, null, 2);
        if (this.topicsStats.length > 0) {
        } else {
            this.pulseService.getTopicsStats();
        }
    }

    sub_store() {
        this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
            if (res) { 
                console.log(res)
                this.userId = res.userId;
            }
        });

        this.subscription.add(this.store.select(rootReducer.get_topic_list).subscribe(topics => {
            if (topics) {
                this.topicList = topics;
                this.initTopicsCount();
            } else {
                this.connectService.getTopics();
            }
        }));
        this.subscription.add(this.store.select(rootReducer.get_topics_stats).subscribe(topicsStats => {
            this.topicsStats = topicsStats;
        }));
        this.subscription.add(this.store.select(rootReducer.get_topics_stats_maped).subscribe(topicsStatsMaped => {
            this.topicsStatsMaped = topicsStatsMaped;
            this.initTopicsCount();
        }));
    }
    private initTopicsCount() {
        this.topicList.forEach(s => {
            if (s.name) {
                if (this.topicsStatsMaped[s.name]) {
                    // show topic name and count
                    let count = (this.topicsStatsMaped[s.name].count) || 0;
                    if (s.name == "self-publish") {
                        count = 1;
                    }
                    s.nameAndCount = s.name + " (" + count + ")";
                } else {
                    // show only topic name
                    s.nameAndCount = s.name;
                }
            }
        });
    }

    typeaheadOnSelect(e: TypeaheadMatch): void {
        var value = e.value;
        var tp = { name: value }
        if (tp.name) this.m.topics.push(tp)
        this.m.search = false;
    }

    ut_search(searchText) {
        this.m.search = true;
        if (searchText.length >= 1) {
            this.topicList.forEach(s => {
                if (s.name) {
                    if (!this.m.searchedTopics.includes(s.nameAndCount)) {
                        this.m.searchedTopics.push(s.nameAndCount);
                    }
                }
            });
        }
    }

    ut_resetSearchBox() {
        this.m.searchText = "";
        this.m.search = false;
    }

    searchTopic() {
        this.m.search = true;
    }

    ut_selectedMonths() {
        var months = "";
        if (this.months.length > 0) {
            _.each(this.months, function (v, k) {
                if (v.checked) { months += v.name + ',' }
            });
            months = months.slice(0, -1);
            if (!months) { months = "Every / Any"; }
        }
        else {
            months = "Every / Any";
        }
        return months;
    }

    ut_selectedDOW() {
        var dow = "";
        if (this.dow.length > 0) {
            _.each(this.dow, function (v, k) {
                if (v.checked) { dow += v.name + ',' }
            });
            dow = dow.slice(0, -1);
            if (!dow) { dow = "Every / Any"; }
        }
        else {
            dow = "Every / Any";
        }
        return dow;
    }
    ut_publishToTopicDryRun() {
        const topicName = this.m.selfPublish ? "self-publish" : this.m.searchText.split(" ")[0].trim();
        const selectedTopic = this.topicList.find(t => t.name.includes(topicName));
        if (selectedTopic) {
            const topicId = selectedTopic.id;
            const { mainTopicName, mainTopicId, name } = selectedTopic;
            const data: CronData = {
                ...this.m.cron,
                month: this.months.filter(m => m.checked).map(m => m.name),
                dow: this.dow.filter(m => m.checked).map(m => m.name)
            };
            const pageData = PageData[this.router.url];
            const metadata = {
                mediums: this.m.mediums.filter(m => m.checked).map(m => m.name.toLowerCase()),
                schedule: this.m.schedule,
                cron: this.toCronExpression(data),
                dryRun: true,
                selfPublish: this.m.selfPublish,
                ...pageData
            }
            const kafkaMessage: KafkaMessage = {
                mainTopicName, mainTopicId, topic: name, topicId,
                payload: this.appService.publisherPayload,
                metadata
            }
            this.pulseService.pushToKafka(kafkaMessage);
        } else {
            console.error("No topic is selected");
        }
    }
    ut_publishToTopic() {
        const topicName = this.m.selfPublish ? "self-publish" : this.m.searchText.split(" ")[0].trim();
        const selectedTopic = this.topicList.find(t => t.name.includes(topicName));
        if (selectedTopic) {
            const topicId = selectedTopic.id;
            const { mainTopicName, mainTopicId, name } = selectedTopic;
            const data: CronData = {
                ...this.m.cron,
                month: this.months.filter(m => m.checked).map(m => m.name),
                dow: this.dow.filter(m => m.checked).map(m => m.name)
            };
            const pageData = PageData[this.router.url];
            const metadata = {
                mediums: this.m.mediums.filter(m => m.checked).map(m => m.name.toLowerCase()),
                schedule: this.m.schedule,
                cron: this.toCronExpression(data),
                selfPublish: this.m.selfPublish,
                ...pageData
            }
            const kafkaMessage: KafkaMessage = {
                mainTopicName, mainTopicId, topic: name, topicId,
                payload: this.appService.publisherPayload,
                metadata
            }
            this.pulseService.pushToKafka(kafkaMessage);
        } else {
            console.error("No topic is selected");
        }
    }
    /**
     * to convert this data to cron expression
    */
    toCronExpression(data: CronData): string {
        const monthMap: { [key: string]: string } = {
            'Jan': '1', 'Feb': '2', 'Mar': '3', 'Apr': '4',
            'May': '5', 'Jun': '6', 'Jul': '7', 'Aug': '8',
            'Sep': '9', 'Oct': '10', 'Nov': '11', 'Dec': '12'
        };
        const dowMap: { [key: string]: string } = {
            'Sun': '0', 'Mon': '1', 'Tue': '2', 'Wed': '3', 'Thu': '4', 'Fri': '5', 'Sat': '6'
        };
        const month = data.month.map(m => monthMap[m]).join(',') || '*';
        const dow = data.dow.map(d => dowMap[d]).join(',') || '*';
        return `${data.minute} ${data.hour} ${data.day} ${month} ${dow}`;
    }
    ut_onChangeSelfPublish() {
        this.m.isTopicFieldDisabled = this.m.selfPublish;
    }

    ut_setupCalendar() {
        const topicName = this.m.searchText.split(" ")[0].trim();
        const selectedTopic = this.topicList.find(t => t.name.includes(topicName));
        if (selectedTopic) {
            const topicId = selectedTopic.id;
            const { mainTopicName, mainTopicId, name } = selectedTopic;
            const data: CronData = {
                ...this.m.cron,
                month: this.months.filter(m => m.checked).map(m => m.name),
                dow: this.dow.filter(m => m.checked).map(m => m.name)
            };

            var cron = this.toCronExpression(data)

            var payload = {
                "Title": this.m.title,
                "CronExpression": cron,
                "EventCategory": "Event",
                "Topic": topicId,
                "Duration": this.m.duration,
                "UserId": this.userId
            }

            this.ctapi.addCalendar(payload).subscribe();
        }
    }
}