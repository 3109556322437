import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxQryOptionsComponent } from './ngxQryOptions/component';
import { NgxFilterComponent } from './ngxFilter/component';
import { AceEditorModule } from 'ng2-ace-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { AgGridModule } from "ag-grid-angular/main";
import { DragulaModule } from 'ng2-dragula';
import { GoogleChart } from './google-chart-dir';
import { NgxBarcodeModule } from 'ngx-barcode';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TreeModule } from 'angular-tree-component';
import { ScopeCheckDirective } from './scopeCheck.directive';
import { MultiScopeCheckDirective } from './multiScopeChk.directive';
import { OrderByPipe } from './orderBy.pipe';
import { RoundUpPipe } from './roundUp.pipe';
import { SearchPipe } from './search.pipe';
import { SumPipe } from './sum.pipe';
import { WfSearchPipe } from './wfSearch.pipe';
import { MinToHmPipe } from './minToHm.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';
import { FilterPipe } from './ngForFilter.pipe';
import { TTPoolFilterPipe } from './ttPoolFilter.pipe';
import { SearchFilterPipe } from './searchFilter.pipe';
import { SearchAllFieldsPipe } from './searchAllFields.pipe';
import { HighlightSearch } from './highlight.pipe';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AutofocusDirective } from './autofocus.directive';
import { TagInputModule } from 'ngx-chips';
import { SMSBalanceComponent } from './components/smsBalance/component';
import { SMSDlrReportComponent } from './components/sms-dlr-report/component';
import { FinanceComponent } from './components/finance/component';
import { SMSExceptionReportComponent } from './components/sms-exception-report/component';
import { SMSUsageChartComponent } from './components/sms-usage-chart/component';
import { DateRangeOptionsComponent } from './dateRangeOptions/component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InstituteSetupComponent } from './components/institute-setup/component';
import { ConnectSetupComponent } from './components/connect-setup/component';
import { AcadSetupComponent } from './components/acad-setup/component';
import { StudentEnrollmentSetupComponent } from './components/student-enrollment/component';
import { CalendarSetupComponent } from './components/calendar-setup/component';
import { UserAccessComponent } from './components/user-access/component';
import { FileUploadModule } from 'ng2-file-upload';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { GoogleMeetComponent } from './components/google-meet/component';
import { MathjaxComponent } from './components/mathjax/component';
import { FileUploadComponent } from './components/file-upload/component';
import { TagComponent } from './components/tag/component';
import { CountdownTimerComponent } from './components/countdownTimer/component';
import { FormatTimePipe } from './formatTime.pipe';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { QaEditorComponent } from './components/qa-editor/component';
import { QaOptionComponent } from './components/qa-option/component';
import { QaAnswerComponent } from './components/qa-answer/component';
import { MarkedOptionsFactory } from '../app/service/marked-options-factory';
import { HttpClient } from '@angular/common/http';
import { FilterSearchComponent } from './components/filter-search/component';
import { VxComponent } from './components/vx/component';
import { VxEditComponent } from './components/vx-edit/component';
import { TreeViewComponent } from './components/tree-view/component';
// import { NavTreeComponent } from './components/nav-tree/component';
import { TitleTreeComponent } from './components/title-tree/component';
import { VxEditorComponent } from './components/vx-editor/component';
import { PublishComponent } from './components/publish/component';
import { PublishToComponent } from './components/publishTo/component';
import { PaymentComponent } from './components/payment/component';
import { PayuPaymentComponent } from './components/payu-payment/component';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { MentionModule } from 'angular-mentions';
import { CtYtComponent } from './components/ct-yt/ct-yt.component';
import { CurriculumProgressComponent } from './components/curriculum-progress/component';
import { CurriculumPublishedComponent } from './components/curriculum-published/component';
import { ProgressBarModule } from "angular-progress-bar";
import { SubscriptionsComponent } from './components/subscriptions/component';
import { PublishedContentComponent } from './components/published-content/component';
import { GvBarchartComponent } from './components/google-vegalite/gv-barchart/gv-barchart.component';
import { ChartDashboardComponent } from './components/chart-dashboard/chart-dashboard.component';
import { GvLinechartComponent } from './components/google-vegalite/gv-linechart/gv-linechart.component';
import { GvPiechartComponent } from './components/google-vegalite/gv-piechart/gv-piechart.component';
import { GvAreachartComponent } from './components/google-vegalite/gv-areachart/gv-areachart.component';
import { GvScatterchartComponent } from './components/google-vegalite/gv-scatterchart/gv-scatterchart.component';
import { GvTableComponent } from './components/google-vegalite/gv-table/gv-table.component';
import { GGaugechartComponent } from './components/google-vegalite/g-gaugechart/g-gaugechart.component';
import { DashPanelComponent } from './components/google-vegalite/dash-panel/dash-panel.component';
import { GoogleTable } from './google-table.dir';
import { MetadataWithTypeaheadComponent } from './components/metadata-with-typeahead/component';
import { CardComponent } from './components/card/component';
import { DesignationComponent } from './components/designation/component';
import { OccupationComponent } from './components/occupation/component';
import { ContactInfoComponent } from './components/contact-info/component';
import { AddressComponent } from './components/address/component';
import { HtmlViewComponent } from './components/html-view/component';
import { LMSCalendarComponent } from './components/calendar/component';

//Module
@NgModule({
  imports: [
    AceEditorModule,
    AgGridModule.withComponents([]),
    BsDatepickerModule.forRoot(),
    CommonModule,
    TimepickerModule.forRoot(),
    DragulaModule.forRoot(),
    FormsModule,
    MentionModule,
    NgxBarcodeModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxSpinnerModule,
    PopoverModule,
    ReactiveFormsModule,
    RouterModule,
    TabsModule,
    TreeModule.forRoot(),
    TypeaheadModule.forRoot(),
    FileUploadModule,
    TagInputModule,
    ProgressBarModule,
    NgxDnDModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: MarkedOptionsFactory,
      },
    })
  ],
  declarations: [
    NgxQryOptionsComponent,
    NgxFilterComponent,
    GoogleChart,
    ScopeCheckDirective,
    MultiScopeCheckDirective,
    OrderByPipe,
    RoundUpPipe,
    SumPipe,
    SearchPipe,
    WfSearchPipe,
    MinToHmPipe,
    SafeHtmlPipe,
    SearchFilterPipe,
    SearchAllFieldsPipe,
    FilterPipe,
    TTPoolFilterPipe,
    HighlightSearch,
    AutofocusDirective,
    SMSBalanceComponent,
    SMSDlrReportComponent,
    FinanceComponent,
    SMSExceptionReportComponent,
    SMSUsageChartComponent,
    DateRangeOptionsComponent,
    InstituteSetupComponent,
    ConnectSetupComponent,
    AcadSetupComponent,
    StudentEnrollmentSetupComponent,
    CalendarSetupComponent,
    UserAccessComponent,
    GoogleMeetComponent,
    MathjaxComponent,
    FileUploadComponent,
    TagComponent,
    CountdownTimerComponent,
    FormatTimePipe,
    QaEditorComponent,
    QaOptionComponent,
    QaAnswerComponent,
    FilterSearchComponent,
    VxComponent,
    VxEditComponent,
    TreeViewComponent,
    // NavTreeComponent,
    TitleTreeComponent,
    VxEditorComponent,
    PublishComponent,
    PublishToComponent,
    PaymentComponent,
    PayuPaymentComponent,
    CtYtComponent,
    CurriculumProgressComponent,
    CurriculumPublishedComponent,
    SubscriptionsComponent,
    PublishedContentComponent,
    GvBarchartComponent,
    GvLinechartComponent,
    GvPiechartComponent,
    GvAreachartComponent,
    GvScatterchartComponent,
    GvTableComponent,
    GGaugechartComponent,
    DashPanelComponent,
    ChartDashboardComponent,
    GoogleTable,
    MetadataWithTypeaheadComponent,
    CardComponent,
    DesignationComponent,
    OccupationComponent,
    ContactInfoComponent,
    AddressComponent,
    HtmlViewComponent,
    LMSCalendarComponent
  ],
  exports: [
    NgxQryOptionsComponent,
    NgxFilterComponent,
    AceEditorModule,
    DragulaModule,
    GoogleChart,
    NgxBarcodeModule,
    FormsModule,
    BsDatepickerModule,
    TimepickerModule,
    AgGridModule,
    TypeaheadModule,
    ScopeCheckDirective,
    MentionModule,
    MultiScopeCheckDirective,
    OrderByPipe,
    RoundUpPipe,
    SumPipe,
    SearchPipe,
    WfSearchPipe,
    MinToHmPipe,
    SafeHtmlPipe,
    FilterPipe,
    TTPoolFilterPipe,
    SearchFilterPipe,
    SearchAllFieldsPipe,
    HighlightSearch,
    PopoverModule,
    AutofocusDirective,
    SMSBalanceComponent,
    SMSDlrReportComponent,
    FinanceComponent,
    SMSExceptionReportComponent,
    SMSUsageChartComponent,
    TreeModule,
    DateRangeOptionsComponent,
    NgMultiSelectDropDownModule,
    InstituteSetupComponent,
    ConnectSetupComponent,
    AcadSetupComponent,
    StudentEnrollmentSetupComponent,
    CalendarSetupComponent,
    UserAccessComponent,
    FileUploadModule,
    GoogleMeetComponent,
    MathjaxComponent,
    FileUploadComponent,
    TagComponent,
    CountdownTimerComponent,
    FormatTimePipe,
    MarkdownModule,
    QaEditorComponent,
    QaOptionComponent,
    QaAnswerComponent,
    FilterSearchComponent,
    VxComponent,
    VxEditComponent,
    TreeViewComponent,
    // NavTreeComponent,
    TitleTreeComponent,
    VxEditorComponent,
    PublishComponent,
    PublishToComponent,
    PaymentComponent,
    PayuPaymentComponent,
    CtYtComponent,
    NgxDnDModule,
    ProgressBarModule,
    CurriculumProgressComponent,
    CurriculumPublishedComponent,
    SubscriptionsComponent,
    PublishedContentComponent,
    GvBarchartComponent,
    GvLinechartComponent,
    GvPiechartComponent,
    GvAreachartComponent,
    GvScatterchartComponent,
    GvTableComponent,
    GGaugechartComponent,
    DashPanelComponent,
    ChartDashboardComponent,
    GoogleTable,
    MetadataWithTypeaheadComponent,
    CardComponent,
    DesignationComponent,
    OccupationComponent,
    ContactInfoComponent,
    AddressComponent,
    HtmlViewComponent,
    LMSCalendarComponent
  ],
  entryComponents: [PublishComponent]
})
export class SharedModule { }
