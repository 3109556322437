import { Component, OnInit, ElementRef, TemplateRef, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

class Model {
    title: string = "";
}

@Component({
    selector: 'publish',
    templateUrl: './component.html',
    styleUrls: ['./component.css']
})

export class PublishComponent implements OnInit {
    public m: Model;
    modalRef: BsModalRef;
    @Input() publishFrom: string;
    @Input() openComponentModal: any;
    @Input() showIcon: any;
    @Input() classSetup: any;
    @Output() classSetupPublish = new EventEmitter<any>(); 
    @ViewChild('publish') publish!: TemplateRef<any>;
    constructor(private store: Store<rootReducer.State>, private el: ElementRef, private modalService: BsModalService) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    init_model() {
        this.m = new Model();
    }

    init_store() {
        if(this.showIcon && this.showIcon == false) this.showIcon;
        else this.showIcon = true;

        if(this.openComponentModal == true) this.openModal(this.publish);

        if(this.classSetup == true) this.classSetupPublish.emit();
    }

    sub_store() { }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false });
    }

    ut_close() {
        this.modalRef.hide();
    }

    handleEvent(event) {
        event.stopPropagation();
    }
}